import { useMemo } from 'react'
import { ChainId } from '@uniswap/sdk-core'
import { SUPPORTED_NETWORK_VERSIONS } from 'constants/networks'

export const useChainIdFromUrl = () => {
  const fullUrl = window.location.href

  const chainId = useMemo(() => {
    const segments = fullUrl.split('/').filter(Boolean)
    const foundNetwork = SUPPORTED_NETWORK_VERSIONS.find((network) =>
      segments.some((segment) => segment.toLowerCase() === network.route.toLowerCase()),
    )

    return foundNetwork ? foundNetwork.chainId : ChainId.MAINNET
  }, [fullUrl])

  return chainId
}
