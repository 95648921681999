// used to mark unsupported tokens, these are hosted lists of unsupported tokens

export const UNSUPPORTED_LIST_URLS: string[] = []
export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
export const ARBITRUM_LIST = 'https://bridge.arbitrum.io/token-list-42161.json'
export const POLYGON_LIST =
  'https://raw.githubusercontent.com/keanlaurens/lif3-v3-info/main/src/constants/tokenLists/polygon.tokenlist.json?token=GHSAT0AAAAAACHEXL2FFZKFUZKORJHTKPIQZLZYYCQ'
// export const CELO_LIST = 'https://celo-org.github.io/celo-token-list/celo.tokenlist.json'
export const BNB_LIST =
  'https://raw.githubusercontent.com/keanlaurens/lif3-v3-info/main/src/constants/tokenLists/bsc.tokenlist.json?token=GHSAT0AAAAAACHEXL2EZH2FN7QND2B5WA2WZLZYYDQ'
export const FANTOM_LIST =
  'https://raw.githubusercontent.com/keanlaurens/lif3-v3-info/main/src/constants/tokenLists/fantom.tokenlist.json?token=GHSAT0AAAAAACHEXL2E55HAPPGBKBIW6WBAZLZYYDA'

export const LIFECHAIN_TESTNET_LIST =
  'https://raw.githubusercontent.com/keanlaurens/lif3-v3-info/main/src/constants/tokenLists/lifechain-testnet.tokenlist.json?token=GHSAT0AAAAAACHEXL2ELL6EWDTGZTXYJIPUZLZYHLQ'

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  // OPTIMISM_LIST,
  // ARBITRUM_LIST,
  POLYGON_LIST,
  // CELO_LIST,
  FANTOM_LIST,
  BNB_LIST,
  LIFECHAIN_TESTNET_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [POLYGON_LIST, BNB_LIST, FANTOM_LIST, LIFECHAIN_TESTNET_LIST]
