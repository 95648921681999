import { RowFixed, RowBetween } from 'components/Row'
import {
  // AvalancheNetworkInfo,
  // BNBNetworkInfo,
  // CeloNetworkInfo,
  // PolygonNetworkInfo,
  SUPPORTED_NETWORK_VERSIONS,
  NetworkInfo,
} from 'constants/networks'
import useTheme from 'hooks/useTheme'
import React, { useState, useRef } from 'react'
import { ChevronDown } from 'react-feather'
import { useActiveNetworkVersion } from 'state/application/hooks'
import styled from 'styled-components'
import { StyledInternalLink, TYPE } from 'theme'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { AutoColumn } from 'components/Column'
import { EthereumNetworkInfo } from '../../constants/networks'
import { rgba } from 'polished'

const Container = styled.div`
  position: relative;
  z-index: 40;
`

const Wrapper = styled.div`
  border-radius: 12px;
  background: ${({ theme }) => theme.bg1};
  padding: 6px 8px;
  margin-right: 12px;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const LogaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 20px;
`

const LogoWrapper = styled.img`
  max-width: 20px;
  fit-content: contain;
`

const FlyOut = styled.div`
  background: linear-gradient(
    180deg,
    rgba(54, 61, 77, 0.9) 0%,
    rgba(49, 56, 69, 0.9) 49.48%,
    rgba(43, 48, 60, 0.9) 69.79%,
    rgba(54, 61, 77, 0.9) 100%
  );
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 12px;
  padding: 16px 0;
  width: 270px;
`

const NetworkRow = styled(RowBetween)<{ active?: boolean; disabled?: boolean; color?: string }>`
  padding: 6px 8px;
  background: ${({ color, active }) => (active ? color : 'none')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : 1)};
  :hover {
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.7)};
  }
`

// const Badge = styled.div<{ $bgColor?: string }>`
//   background-color: ${({ theme, $bgColor }) => $bgColor ?? theme.bg4};
//   border-radius: 6px;
//   padding: 2px 6px;
//   font-size: 12px;
//   font-weight: 600;
// `

const GreenDot = styled.div`
  height: 12px;
  width: 12px;
  margin-right: 12px;
  background: ${({ theme }) => theme.green1};
  border-radius: 50%;
  position: absolute;
  border: 2px solid black;
  right: -16px;
  bottom: -4px;
`

const TestnetTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const TestnetLine = styled.div`
  height: 1px;
  width: 80%;
  background: ${({ theme }) => rgba(theme.white, 0.5)};
`

interface NetworkLinkProps {
  network: NetworkInfo
  activeNetwork: NetworkInfo
  onClick: () => void
}

const NetworkLink: React.FC<NetworkLinkProps> = ({ network, activeNetwork, onClick }) => {
  const theme = useTheme()

  return (
    <StyledInternalLink key={network.id} to={`${network === EthereumNetworkInfo ? '' : '/' + network.route}/`}>
      <NetworkRow onClick={onClick} active={activeNetwork.id === network.id} color={network.bgColor}>
        <RowFixed>
          <LogaContainer>
            <LogoWrapper src={network.imageURL} />
            {activeNetwork.id === network.id && <GreenDot />}
          </LogaContainer>
          <TYPE.main ml="12px" color={theme?.white}>
            {network.name}
          </TYPE.main>
        </RowFixed>
      </NetworkRow>
    </StyledInternalLink>
  )
}

export default function NetworkDropdown() {
  const [activeNetwork] = useActiveNetworkVersion()
  const theme = useTheme()
  const testnetNetworks = SUPPORTED_NETWORK_VERSIONS.filter((network) => network.testnet)
  const mainnetChains = SUPPORTED_NETWORK_VERSIONS.filter((network) => !network.testnet)

  const [showMenu, setShowMenu] = useState(false)
  const node = useRef<HTMLDivElement>(null)
  useOnClickOutside(node, () => setShowMenu(false))

  return (
    <Container ref={node}>
      <Wrapper onClick={() => setShowMenu(!showMenu)}>
        <RowFixed>
          <LogoWrapper src={activeNetwork.imageURL} />
          <TYPE.main fontSize="14px" color={theme?.white} ml="8px" mt="-2px" mr="2px" style={{ whiteSpace: 'nowrap' }}>
            {activeNetwork.name}
          </TYPE.main>
          {/* {[EthereumNetworkInfo, PolygonNetworkInfo, CeloNetworkInfo, BNBNetworkInfo, AvalancheNetworkInfo].includes(
            activeNetwork,
          ) ? null : (
            <Badge $bgColor={activeNetwork.primaryColor} style={{ margin: '0 4px' }}>
              L2
            </Badge>
          )} */}
          <ChevronDown size="20px" />
        </RowFixed>
      </Wrapper>
      {showMenu && (
        <FlyOut>
          <AutoColumn $gap="16px">
            <TYPE.main color={rgba(theme.white, 0.5)} fontWeight={400} fontSize="13px" paddingX="8px">
              Select network
            </TYPE.main>
            {mainnetChains.map((network) => (
              <NetworkLink
                key={network.id}
                network={network}
                activeNetwork={activeNetwork}
                onClick={() => setShowMenu(false)}
              />
            ))}
            <TestnetTextContainer>
              <TYPE.main color={theme?.white} fontWeight={400} fontSize="10px" paddingX="8px">
                Testnets
              </TYPE.main>
              <TestnetLine />
            </TestnetTextContainer>
            {testnetNetworks.map((network) => (
              <NetworkLink
                key={network.id}
                network={network}
                activeNetwork={activeNetwork}
                onClick={() => setShowMenu(false)}
              />
            ))}
          </AutoColumn>
        </FlyOut>
      )}
    </Container>
  )
}
